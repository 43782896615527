import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "axios-hooks";

import Chatbox from "@/components/Chat/Chatbox";
import CustomToaster from "@/components/CustomToaster";
import Loader from "@/components/loader/loader";
import ThemeContext from "@/themeContext/themeContext";

import "./Style.scss";

const Room = () => {
  //  eslint-disable-next-line
  const [{ loading }, makeRequest] = useAxios({}, { manual: true });
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const [chatData, setChatData] = useState({});
  const [toasterState, setToasterState] = useState({
    isShown: false,
    message: "",
    messageType: "",
  });

  const onClose = useCallback(
    () => {
      navigate(-1);
    },
    //  eslint-disable-next-line
    [],
  );
  const { theme } = useContext(ThemeContext);

  const handleToasterClose = () => {
    setToasterState({ ...toasterState, isShown: false });
  };

  useEffect(
    () => {
      if (sessionId) {
        makeRequest({
          url: `chats/session/${sessionId}`,
          method: "get",
        })
          .then(({ data }) => {
            setChatData(data);
          })
          .catch((error) => {
            if (error?.response?.status === 500) {
              setToasterState({
                isShown: true,
                message: "Something Went wrong",
                messageType: "error",
              });
            } else if (!error?.response?.data?.error_message?.includes("Rate limit reached")) {
              setToasterState({
                isShown: true,
                message: error?.response?.data?.error_message,
                messageType: "error",
              });
            }
          });
      }
    },
    //  eslint-disable-next-line
    [sessionId],
  );

  return (
    <>
      {/* <DrawerAppBar /> */}

      {loading && <Loader />}

      <div className={theme === "light" ? "room-wrapper" : "room-wrapper room-wrapper-dark"}>
        <div className="room-inner-wrapper">
          <CustomToaster
            isShown={toasterState.isShown}
            handleClose={handleToasterClose}
            message={toasterState.message}
            messageType={toasterState.messageType}
          />
          <div className="chat-window">
            {sessionId && <Chatbox onClose={onClose} sessionId={sessionId} chatData={chatData} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Room;
