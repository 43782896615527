import { useContext } from "react";
import Markdown from "react-markdown";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { format } from "date-fns";

import MiniLogoii from "@/assets/images/miniLogoii.svg";
import type { IUserDetail } from "@/pages/dashboard/Dashboard";
import ThemeContext from "@/themeContext/themeContext";

const Message = ({ message }: { message: any }) => {
  const isBot = message.sender === "bot" || message?.sent_by === 0;
  const { theme } = useContext(ThemeContext);
  const userBg = theme === "light" ? "circle-light" : "circle-dark";
  const userInfo: IUserDetail = JSON.parse(localStorage.getItem("user")!);
  // console.log("message", message, theme);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "column" : "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
          }}
        >
          <Avatar className={isBot ? "bot-bg" : userBg}>{isBot ? <img src={MiniLogoii} alt="mini logo" /> : ""}</Avatar>
          <span className="user-name">
            {isBot
              ? "gaiia"
              : userInfo && userInfo.name
                ? userInfo.name
                : userInfo && userInfo.email
                  ? userInfo.email.split("@")[0]
                  : "guest"}
          </span>
        </Box>

        <Paper
          variant="outlined"
          className={isBot ? "bot-chat-bg" : "user-chat-bg"}
          sx={{
            p: 2,
            ml: isBot ? 1 : 0,
            mr: isBot ? 0 : 1,
            borderRadius: isBot ? "20px 20px 20px 20px" : "20px 20px 20px 20px",
            marginTop: "10px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            "& * ": {
              fontFamily: "Inter",
              color: "#2d100a",
            },
          }}
        >
          {isBot ? (
            <Markdown>{message.text ?? message.message}</Markdown>
          ) : (
            <Typography variant="body1">{message.text ?? message.message}</Typography>
          )}
        </Paper>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "0px",
            gap: "0px",
            fontFamily: "Inter",
            color: "#7E7E7E",
            fontSize: "13px",
            fontWeight: 400,
            letterSpacing: "0.664px",
          }}
        >
          {message.updated_at && format(message.updated_at, "H:mm a")}
        </div>
      </Box>
    </Box>
  );
};

export default Message;
