import type { Dispatch, FC, SetStateAction } from "react";
import React, { useContext, useEffect, useRef, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";

import ThemeContext from "@/themeContext/themeContext";

import styles from "./AudioRecording.module.scss";

type Bars = number[];
type Transcript = string;

type AudioRecordingProps = {
  setIsAudioOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (text: string) => void;
  isOpen: boolean;
};

const totalPadding = 40; // 20px on each side
const largeElementsWidth = 80; // 2 elements of 40px width each
const barWidth = 2; // Width of each bar
const barGap = 2; // Gap between bars
const gapBetweenBigElements = 32; // gap between 3 elements 16
const handleGetBarSize = (width: number) => {
  const containerWidth = width || 600; // Default width if not found

  // Calculate the number of bars
  const availableWidth = containerWidth - totalPadding - largeElementsWidth - gapBetweenBigElements;
  const numBars = Math.floor(availableWidth / (barWidth + barGap));

  return numBars;
};
const AudioRecording: FC<AudioRecordingProps> = ({ setIsAudioOpen, onSubmit, isOpen }) => {
  const [isListening, setIsListening] = useState<boolean>(false);
  const audioContainer = document.getElementById("audioContainer");
  const [bars, setBars] = useState<Bars>(
    new Array(handleGetBarSize(audioContainer?.offsetWidth ?? window.innerWidth)).fill(0),
  );
  const [transcript, setTranscript] = useState<Transcript>("");
  const [fullTranscript, setFullTranscript] = useState<Transcript>("");
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const animationRef = useRef<number>(0);
  const tempTranscriptRef = useRef<Transcript>("");
  const [width, setWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const { theme } = useContext(ThemeContext);
  // console.log(fullTranscript, transcript, bars, isListening)

  // Function to update width
  const updateWidth = () => {
    if (containerRef.current) {
      const w = containerRef.current.offsetWidth;
      setWidth(w);
      const _bars = handleGetBarSize(w);
      setBars(new Array(_bars).fill(0));
    }
  };

  useEffect(() => {
    updateWidth();
  }, [isOpen]);

  useEffect(() => {
    // Initial width set
    updateWidth();

    // Create a ResizeObserver to watch for size changes
    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });

    // Observe the container element
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup on component unmount
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.error("Speech Recognition API not supported in this browser.");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      let interimTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptPart = event.results[i][0].transcript;

        if (event.results[i].isFinal) {
          tempTranscriptRef.current += transcriptPart + " ";
        } else {
          interimTranscript += transcriptPart;
        }
      }
      setTranscript(interimTranscript); // Update interim transcript
    };

    recognition.onerror = (event: any) => {
      console.error("Speech recognition error:", event.error);
    };

    recognition.onend = () => {
      setFullTranscript(tempTranscriptRef.current);
    };

    recognitionRef.current = recognition;
  }, []);

  const reset = () => {
    setTranscript("");
    setFullTranscript("");
    const _bars = handleGetBarSize(width);
    setBars(new Array(_bars).fill(0));
  };

  const startListening = async () => {
    setIsListening(true);
    setTranscript("");
    setFullTranscript("");
    tempTranscriptRef.current = "";

    // Reset bars to clear previous visualization
    setBars(new Array(handleGetBarSize(width)).fill(0));

    recognitionRef.current?.start();

    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioCtx.createAnalyser();
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const source = audioCtx.createMediaStreamSource(stream);
    source.connect(analyser);

    analyserRef.current = analyser;
    setAudioStream(stream);

    const animate = () => {
      if (analyserRef.current) {
        analyserRef.current.getByteFrequencyData(dataArray);

        const maxBarHeight = 40;
        const normalizedHeights = dataArray.map((value) => (value / 256) * maxBarHeight);

        setBars((prevBars) => {
          return [...prevBars.slice(1), normalizedHeights[0]];
        });
      }

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);
  };

  const stopListening = (withSubmit: boolean) => {
    setIsListening(false);
    recognitionRef.current?.stop();
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
    }
    cancelAnimationFrame(animationRef.current);
    setTimeout(() => {
      if (withSubmit) {
        onSubmit(tempTranscriptRef.current || transcript);
      }
      reset();
      setIsAudioOpen(false);
    }, 500); // Add a delay before finalizing
  };

  const dismissAudio = () => {
    stopListening(false);
  };

  if (!isListening) {
    return (
      <KeyboardVoiceIcon
        onClick={() => {
          setIsAudioOpen(true);
          startListening();
        }}
        fontSize="large"
        sx={{ cursor: "pointer", color: theme === "light" ? "#000" : "#fff" }}
      />
    );
  }

  const maxWidth = width - totalPadding - largeElementsWidth - gapBetweenBigElements;

  const burgundy = "#2D100A";
  const roseBeige = "#E4D2C4";
  const dark = "#27303C";
  const iconStyles = {
    color: theme === "light" ? dark : roseBeige,
    "&:hover": {
      color: roseBeige,
    },
  };

  const iconBg = { background: theme === "light" ? roseBeige : burgundy };
  const emptyBar = theme === "light" ? "#ccc" : "#ccc";
  const filledBar = theme === "light" ? roseBeige : burgundy;
  const contianerBg = theme === "light" ? burgundy : roseBeige;

  return (
    <div
      className={styles.visualizerContainer}
      style={{ background: contianerBg }}
      ref={containerRef}
      id="audioContainer"
    >
      <IconButton onClick={dismissAudio} sx={iconBg}>
        <CloseIcon sx={iconStyles} />
      </IconButton>

      <Box
        className={styles.visualizer}
        sx={{
          maxWidth: `${maxWidth}px`,
        }}
      >
        {bars.map((barHeight, index) => {
          return (
            <div
              key={index}
              className={styles.bar}
              style={{
                height: `${Math.max(barHeight, 4)}px`,
                backgroundColor: barHeight === 0 ? emptyBar : filledBar,
              }}
            />
          );
        })}
      </Box>
      <IconButton sx={iconBg} onClick={() => stopListening(true)}>
        <CheckIcon sx={iconStyles} />
      </IconButton>
    </div>
  );
};

export default AudioRecording;
